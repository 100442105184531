<template>
  <div class='container'>
    <div class="option" v-for="item in options" :key="item.id" @click="optionCLick(item.url,item.select)">
      <div class="title">
        {{item.title}}
      </div>
      <div style="display: flex;align-items: center;">
        <div style="font-size: 4vw;" v-if="!item.url">
          <p class="unfinished" v-if="!item.value">未完善</p>
          <p v-else>{{item.value}}</p>
        </div>
        <van-icon name="arrow" size="4vw" style="margin-left: 4vw;"/>
      </div>
    </div>
    <van-popup v-model="isPopup" position="bottom" :style="{ height: '40%' }" >
      <van-picker
        title="学习情况"
        show-toolbar
        :columns="studyList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='study'"
      />
      <van-picker
        title="健康状况"
        show-toolbar
        :columns="healthList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='health'"
      />
      <van-picker
        title="父母关系"
        show-toolbar
        :columns="relationList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='relation'"
      />
      <van-picker
        title="家庭氛围"
        show-toolbar
        :columns="ambientList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='ambient'"
      />
      <van-picker
        title="家庭经济情况"
        show-toolbar
        :columns="economicsList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='economics'"
      />
    </van-popup>
    <van-dialog v-model="isDialog" :title="dialogName" :showConfirmButton="false">
      <div class="dialog">
        <textarea v-model="formData.psychological_diagnsis" v-if="dialogName==='学生健康状况'"></textarea>
        <textarea v-model="formData.other" v-else-if="dialogName==='其他描述'"></textarea>
        <div class="btns">
          <button @click="isDialog=false">取消</button>
          <button @click="dialogConfirm">确认</button>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {profileUpdateV2} from '@/api/student.js'
export default {
  data(){
    return{
      options:[
        {id:1,title:'基本信息',url:'info'},
        {id:2,title:'学习情况',url:'',select:'study',value:''},
        {id:3,title:'家庭住址',url:'address'},
        {id:4,title:'爱好特长',url:'hobby'},
        {id:5,title:'健康状况',url:'',select:'health',value:''},
        {id:6,title:'过往病史',url:'case'},
        {id:7,title:'家庭成员',url:'family'},
        {id:8,title:'父母关系',url:'',select:'relation',value:''},
        {id:9,title:'家庭氛围',url:'',select:'ambient',value:''},
        {id:10,title:'家庭经济情况',url:'',select:'economics',value:''},
        {id:11,title:'学习经历',url:'experience'},
        {id:12,title:'重大生活事件',url:'live'},
      ],
      formData: {
          id: '',
          sn: '', // 学籍号
          grade: '', // 年级
          studyClass: '', // 班级
          gender: '', // 性别
          area: '', // 校区
          birthday: '', // 出生日期
          nation: '', // 民族
          birth_place: '', // 籍贯
          situation: '', // 学习情况
          interest: [], // 兴趣
          interest_other: '', // 兴趣
          home_address: '', // 家庭地址
          health_status: '', // 健康状况
          psychological_diagnsis: '', // 心理诊断记录
          past_medical_history: [], // 过往身体病史
          past_medical_history_other: '', // 过往身体病史
          parent_relationship: '', // 父母关系
          family_atmosphere: '', // 家庭气氛
          family_economy: '', // 家庭经济情况
          other: '', // 其他情况
          f_name: '', // 父亲名字
          f_age: '', // 年龄
          f_occupation: '', // 职业
          f_characteristics: '', // 个人特点
          f_like_level: '', // 喜爱程度 1 喜欢 2 不喜欢
          m_name: '', // 母亲名字
          m_age: '', // 年龄
          m_occupation: '', // 职业
          m_characteristics: '', // 个人特点
          m_like_level: '', // 喜爱程度 1 喜欢 2 不喜欢
          study: [], // 学习经历
          event: [], // 重大生活事件
          home: [], // 家庭成员
          province: '', // 省
          city: '', // 市
          county: '' // 区
        },
      isPopup:false,
      isDialog:false,
      popupName:'',
      dialogName:'',
      healthVal:'',
      ambientVal:'',
      studyList:['好','一般','差','非常差'],
      healthList:['很好','良好','普通','较差','很差'],
      relationList:['和睦','不和','分居','离异'],
      ambientList:['和睦','普通','欠和谐','沉闷','其他(需补充说明)'],
      economicsList:['良好','一般','较差','差'],
    }
  },
  computed:{
    ...mapState(['student'])
  },
  created(){
    this.getDetail()
  },
  methods:{
    async getDetail(){
      const {id}=this.$route.query
      await this.$store.dispatch('getStudentDetail',id)
      this.formData=this.student
      this.options[1].value=this.formData.situation
      this.options[4].value=this.formData.health_status+`${this.student.psychological_diagnsis?`(${this.student.psychological_diagnsis})`:''}`
      this.options[7].value=this.formData.parent_relationship
      this.options[8].value=this.formData.family_atmosphere+`${this.student.other?`(${this.student.other})`:''}`
      this.options[9].value=this.formData.family_economy
    },
    async submit(){
      const listQuery = {}
      for (const el in this.formData) {
        if (this.formData[el] instanceof Array) {
          if (this.formData[el].length > 0) {
            listQuery[el] = this.formData[el]
          }
        } else if (this.formData[el] !== '') {
            listQuery[el] = this.formData[el]
        }
      }
      const res = await profileUpdateV2(listQuery)
      this.getDetail()
      this.$toast.success('修改信息成功')
    },
    optionCLick(url,select){
      if(url){
        const {id}=this.$route.query
        console.log(id);
        this.$router.push({path:url,query:{id}})
      }else{
        this.popupName=select
        this.isPopup=true
      }
    },
    onConfirm(val){
      console.log(val);
      if(this.popupName==='study'){
        this.formData.situation=val
        this.submit()
      }else if(this.popupName==='health'){
        if(val==='较差'||val==='很差'){
          this.formData.health_status=val
          this.formData.psychological_diagnsis=''
          this.dialogName='学生健康状况'
          this.isDialog=true
        }
      }else if(this.popupName==='ambient'){
        console.log(val==='其他(需补充说明)');
        if(val==='其他(需补充说明)'){
          this.dialogName='其他描述'
          this.formData.family_atmosphere=val.substring(0,2)
          this.formData.other=''
          this.isDialog=true
        }
      }else if(this.popupName==='relation'){
        this.formData.parent_relationship=val
        this.submit()
      }else if(this.popupName==='economics'){
        this.formData.family_economy=val
        this.submit()
      }
      this.isPopup=false
    },
    dialogConfirm(){
      if(this.dialogName==='学生健康状况'){
        if(this.formData.psychological_diagnsis.length<=0){
          // console.log(this.formData.psychological_diagnsis,this.formData.psychological_diagnsis.length);
          this.$toast.fail('内容不能为空')
          return
        }
      }else if(this.dialogName='其他描述'){
        if(this.formData.other.length<=0){
          this.$toast.fail('内容不能为空')
          return
        }
      }
      this.submit()
      this.isDialog=false
    }
  }
}
</script>
<style lang='less' scoped>
::v-deep .van-dialog__header{
  padding-top: 2.6667vw;
}
::v-deep .van-dialog{
  border-radius: 1.3333vw;
}
.container{
  background-color: #fff;
  min-height: 100%;
  .option{
    padding: 0 4vw;
    height: 14.4vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
      font-weight: 550;
      font-size: 4vw;
    }
    .unfinished{
      padding: 1.3333vw 4vw;
      color: #dd3d35;
      border: .2667vw solid #dd3d35;
      border-radius: 1.3333vw;
    }
  }
  .dialog{
    padding: 4vw;
    textarea{
      width: 100%;
      min-height: 26.6667vw;
      background-color: #f8f8f8;
      border-radius: 1.3333vw;
      font-size: 3.4667vw;
    }
    .btns{
      margin-top: 2.6667vw;
      padding: 0 8vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      button{
        padding: 1.3333vw 5.3333vw;
        color: #fff;
        font-size: 4vw;
        // font-weight: 550;
        background-color: #0181fe;
        border-radius: 1.3333vw;
      }
    }
  }
}
</style>